<template>
  <div id="Comp">

  </div>
</template>

<script>
  export default {
    name: "Comp"
  }
</script>
